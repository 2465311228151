<template>
	<div class="bg-blue-lightest">
		<Header />
		<div class="flex flex-col items-center px-6">
			<h1 class="mt-10 md:mt-20 text-center text-5xl text-Blue">Trip Details</h1>
			<div class="hidden md:flex w-full md:w-100">
				<CitiesPicker v-on:search="submit" :selectedDepart="selectedDepart" :selectedDest="selectedDest" :cities="options" />
			</div>
			<div class="md:hidden mt-10 w-full md:w-100">
				<CitiesPickerMobile v-on:search="submit" :selectedDepart="selectedDepart" :selectedDest="selectedDest" :cities="options" />
			</div>

			<div class="mt-8 w-full md:w-3/5">
				<div v-if="loadingTrips" class="flex items-center justify-center py-24">
					<div class="fill-current flex items-center justify-center w-12 h-12 text-Green">
						<Loader />
					</div>
				</div>
				<div v-else-if="trips.length == 0" class="flex flex-col items-center justify-center mt-8 mb-16">
					<div class="w-64">
						<img src="img/not_found.png" />
					</div>
					<p class="text-Blue text-2xl mt-10">Oops! We can't find what you are looking for !</p>
				</div>
				<template v-else>
					<template v-for="(trip, index) in trips" :key="index">
						<div class="mb-4">
							<TripCard :trip="trip" />
						</div>
					</template>
				</template>
			</div>
		</div>
		<Footer />
	</div>
</template>
<script>
import MyHeader from '@/components/MyHeader'
import Myicons from '@/components/Myicons.vue'
import TripCard from '@/components/trip/TripCard.vue'
import Loader from '@/components/Loader.vue'
import Multiselect from 'vue-multiselect'
import { axios } from '../api/index'
import Footer from '@/components/Footer.vue'
import CitiesPicker from '@/components/CitiesPicker'
import CitiesPickerMobile from '@/components/CitiesPickerMobile'

export default {
	components: {
		Header: MyHeader,
		Icon: Myicons,
		TripCard,
		Multiselect,
		Loader,
		Footer,
		CitiesPicker,
		CitiesPickerMobile,
	},
	data() {
		return {
			options: [],
			value: null,
			selectedDepart: null,
			selectedDest: null,
			trips: [],
			loadingTrips: false,
		}
	},
	created() {
		const dep = this.$route.query.dep
		const dest = this.$route.query.dest
		axios
			.get('/cities')
			.then(({ data }) => {
				this.options = data
			})
			.catch((err) => {
			})

		this.fetchTrip(dep, dest)
	},
	methods: {
		submit(values) {
			this.selectedDepart = values.dep;
			this.selectedDest = values.dest;
			this.$router.push({ path: 'trip', query: { dep: this.selectedDepart?.id, dest: this.selectedDest?.id } })
			this.fetchTrip(this.selectedDepart.id, this.selectedDest.id)
		},
		fetchTrip(dep, dest) {
			this.loadingTrips = true
			axios
				.get(`/trips?dep=${dep}&arv=${dest}`)
				.then(({ data }) => {

					const trips = data.trips;

					trips.sort(function (a, b) {
						return new Date('1970/01/01 ' + a.d_time).getTime() - new Date('1970/01/01 ' + b.d_time).getTime()
					})

					this.trips = trips
				})
				.catch((err) => {
				})
				.finally(() => {
					this.loadingTrips = false
				})
		},
	},
}
</script>
<style>
.vs__dropdown-toggle {
	border: none;
}
.vs__selected {
	color: rgb(100, 116, 139);
	@apply text-sm;
}

.vs__selected-options {
	flex-wrap: nowrap;
	max-width: calc(100% - 25px);  
}

.vs__selected {
	display: block;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 100%;
	overflow: hidden;
}
</style>
