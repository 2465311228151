import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Trip from '../views/Trip.vue'
import Destinations from '../views/Destanations.vue'
import DestanationView from '../views/DestanationView.vue'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import TermsUse from '../views/TermsUse.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/trip',
    name: 'Trip',
    component: Trip
  },
  {
    path: '/destinations',
    name: 'Destinations',
    component: Destinations
  },
  {
    path: '/destinations/:id',
    name: 'destinationsView',
    component: DestanationView
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/terms-of-use',
    name: 'TermsUse',
    component: TermsUse
  },

  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
