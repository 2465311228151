<template lang="">
	<div class="w-full flex justify-center items-center px-3 md:px-5 py-3 mt-10 bg-white border rounded-2xl">
		<div class="flex flex-1 w-full items-center">
			<div class="w-1/2 md:w-44">
				<div class="flex items-center text-gray-400 text-base font-semibold">
					<myicons name="localisation" class="px-1 cursor-pointer"></myicons>
					<v-select placeholder="Depart" v-model="selectedDepart" class="w-5/6" label="name" :options="cities"></v-select>
				</div>
			</div>
			<div class="flex items-center justify-center flex-1 mx-2 text-Green">
				<div class="w-4 md:w-6">
					<svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
						<title>Chevron Forward</title>
						<path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M184 112l144 144-144 144" />
					</svg>
				</div>
			</div>
			<div class="w-1/2 md:w-44">
				<div class="flex items-center text-gray-400 text-base font-semibold">
					<myicons name="localisation" class="px-1 cursor-pointer"></myicons>
					<v-select placeholder="Destination" v-model="selectedDest" class="w-5/6" label="name" :options="cities"></v-select>
				</div>
			</div>
		</div>
		<div class="flex ml-3 md:ml-8 items-center justify-end">
			<button @click="submit" class="p-7 bg-Green w-12 rounded-2xl h-12 flex items-center justify-center cursor-pointer">
				<myicons name="search" class="text-white" />
			</button>
		</div>
	</div>
</template>
<script>
import 'vue-select/dist/vue-select.css'
import vSelect from '@/components/vue-select'
import Myicons from '@/components/Myicons'

export default {
	components: {
		Myicons,
		'v-select': vSelect,
	},
	props: {
		cities: {
			type: Array,
			required: true,
		},
		selectedDest: {
			// type: Object,
			required: true,
		},
		selectedDepart: {
			// type: Object,
			required: true,
		},
	},
	methods: {
		submit() {
			if (this.$attrs && !!this.$attrs.onSearch) {
				this.$emit('search', {
					dep: this.selectedDepart,
					dest: this.selectedDest,
				})
			} else {
				this.$router.push({ path: 'trip', query: { dep: this.selectedDepart.id, dest: this.selectedDest.id } })
			}
		},
	},
}
</script>

<style>
.vs__clear,
.vs__open-indicator {
	fill: rgb(155 163 175 / 85%);
}
</style>
