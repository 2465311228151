<template>
	<div class="bg-blue-lightest">
		<!-- Header -->
		<Header />
		<!-- Body -->
		<div class="flex justify-center">
			<div class="flex py-10 container px-6">
				<div class="w-1/2 flex items-center">
					<h1 class="text-Blue text-5xl">Explore Morocco</h1>
				</div>
				<div class="w-1/2">
					<div class="w-full md:w-100">
						<img src="img/explorer.png" alt="" />
					</div>
				</div>
			</div>
		</div>
		<div class="flex justify-center items-center flex-wrap mt-10">
			<div class="container px-4 md:px-0">
				<DestinationsPlaceHolder v-if="loading" />
				<div v-else class="flex flex-wrap -m-2">
					<template v-for="(item, index) in cities" :key="index">
						<div class="w-1/2 md:w-1/4 p-2">
							<router-link :to="generateURL(item)">
								<div class="relative group flex justify-center items-center h-52 w-full rounded-lg overflow-hidden shadow cursor-pointer">
									<img class="object-center object-cover h-full w-full" :src="item.image" alt="city-image" />
									<span class="bottom-0 left-0 right-0 text-center z-10 absolute text-base font-medium text-white py-3">{{ item.name }}</span>
								</div>
							</router-link>
						</div>
					</template>
				</div>
			</div>
		</div>

		<!-- Get the app -->
		<div class="bg-Blue mt-16 md:mt-48 mb-10 md:mb-44">
			<div class="container px-6 py-8 md:py-20 mx-auto relative">
				<div class="w-full md:w-1/2">
					<h1 class="text-white text-5xl mb-6 text-center md:text-left">Get the app</h1>
					<p class="text-white text-xl mb-4 text-center md:text-left">Download the app and start your journey</p>
					<a href="https://play.google.com/store/apps/details?id=com.appmosafir" class="flex justify-center md:justify-start">
						<div>
							<img src="/img/GoogelPlay.png"/>
						</div>
					</a>
				</div>
				<div style="position: absolute; top: -100px; right: 0" class="hidden md:block w-100">
					<img src="img/mobile_app_mockup.png" />
				</div>
			</div>
		</div>

		<div class="mt-20">
			<Footer />
		</div>
	</div>
</template>
<script>
import MyHeader from '@/components/MyHeader.vue'
import Myicons from '@/components/Myicons.vue'
import { axios } from '../api/index'
import 'vue-select/dist/vue-select.css'
import Footer from '@/components/Footer.vue'
import DestinationsPlaceHolder from '@/components/placeholders/DestinationsPlaceHolder'

export default {
	components: {
		Header: MyHeader,
		Icon: Myicons,
		Footer,
		DestinationsPlaceHolder,
	},
	data() {
		return {
			loading: true,
			cities: [],
		}
	},
	created() {
		axios
			.get('/destinations')
			.then(({ data }) => {
				this.cities = data
			})
			.catch((err) => {})
			.finally(() => {
				this.loading = false
			})
	},
	methods: {
		generateURL(item) {
			return `/destinations/${item.id}-${item.name.toLowerCase()}`
		},
	},
}
</script>
