<template>
	<div class="" id="search">
		<HeroSection :cities="cities" />

		<!-- Slider -->
		<ExploreSlider />

		<div class="px-6 md:px-0 mt-14 md:mt-0">
			<!-- Call to action -->
			<div class="container mx-auto flex flex-col items-start md:flex-row my-4 md:my-24 relative">
				<div class="hidden md:flex justify-center w-full mb-4 md:mb-0 md:w-1/3">
					<img class="z-20" src="img/sqr_home_image.png" alt="" />
				</div>
				<div class="flex flex-1">
					<div class="md:px-28 flex flex-col justify-between">
						<h2 class="text-Blue text-3xl md:text-5xl font-thin text-center md:text-left" style="line-height: 55px">
							Plan your journey efficiently<span class="text-Green">.</span>
						</h2>
						<br class="hidden md:block" />
						<br class="md:block" />
						<div class="md:hidden flex justify-center w-full my-4 mb-8 md:mb-0">
							<img class="z-20" src="img/sqr_home_image.png" alt="" />
						</div>
						<p class="text-gray-500 text-lg font-regular leading-7 mb-6 md:mb-0 text-center md:text-left">
							Discover the convenience and flexibility of bus travel in Morocco with Mosafir.ma.<br class="hidden md:block" />
							As a leading Moroccan travel website, Mosafir.ma specializes in providing comprehensive information about bus trips, including dates, times, and stops.
						</p>

						<div class="flex justify-center md:justify-start" >
							<a href="#search" class="py-3 px-10 rounded-lg text-white bg-Green flex items-center justify-center cursor-pointer mt-8 w-48 ">Start Now</a>

						</div>

					</div>
				</div>
			</div>
		</div>

		<!-- Get the app -->
		<div class="md:hidden bg-Blue mt-8 mb-10">
			<div class="container justify-between flex px-6 py-8 md:py-20 mx-auto relative">
				<div class="w-1/2">
					<h1 class="text-white text-3xl mb-6 md:text-left">Get the app</h1>
					<p class="text-white text-xl mb-4 md:text-left">Download the app and start your journey</p>
					<a href="https://play.google.com/store/apps/details?id=com.appmosafir" class="flex mt-6 justify-start md:justify-start">
						<div class="w-48">
							<img src="/img/GoogelPlay.png" />
						</div>
					</a>
				</div>
				<div class="w-1/2 flex justify-end">
					<div class="w-52" style="">
						<img src="img/iphone13.png" />
					</div>
				</div>
			</div>
		</div>

		<div class="hidden md:flex bg-Blue mt-8 md:mt-32 mb-10 md:mb-44">
			<div class="container px-6 md:px-0 py-8 md:py-20 mx-auto relative">
				<div class="w-full md:w-1/2">
					<h1 class="text-white text-5xl mb-6 text-center md:text-left">Get the app</h1>
					<p class="text-white text-xl mb-4 text-center md:text-left">Download the app and start your journey</p>
					<a href="https://play.google.com/store/apps/details?id=com.appmosafir" class="flex justify-center md:justify-start">
						<div class="w-48">
							<img src="/img/GoogelPlay.png" />
						</div>
					</a>
				</div>
				<div style="position: absolute; bottom: 0px; right: 0" class="hidden md:block w-12 md:w-100">
					<img src="img/iphone13-half.png" />
				</div>
			</div>
		</div>

		<div class="px-6 md:px-24">
			<!-- News -->
			<div class="text-Blue text-left md:text-center text-4xl font-normale mt-20 mb-10">
				<a href="#">Mosafir<span class="text-Green">.ma</span></a> <br class="md:hidden" />
				in the News
			</div>
			<div class="flex flex-wrap justify-center items-center mb-20">
				<div v-for="(image, index) in newsLogos" :key="index" class="w-1/3 lg:w-48 p-6 flex items-center justify-center">
					<img class="w-auto" :src="image.src" alt="" />
				</div>
			</div>

			<!-- Reviews -->
			<Reviews />
		</div>

		<!-- Call to action 2 -->
		<div class="flex justify-center mt-10 container mx-auto">
			<div class="w-full md: bg-Blue md:rounded-2xl">
				<div class="w-full px-4 md:px-8 py-16 relative">
					<h1 class="mb-6 md:mb-0 text-white text-5xl leading-50 text-left text-center" style="">
						Your travel starts <br />
						from here<span>.</span>
					</h1>
					<div class="rounded-2xl flex justify-center">
						<div class="hidden md:flex justify-center w-full md:w-100">
							<CitiesPicker :selectedDepart="null" :selectedDest="null" :cities="cities" />
						</div>
						<div class="md:hidden w-full md:w-100">
							<CitiesPickerMobile :selectedDepart="null" :selectedDest="null" :cities="cities" />
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="md:mt-10">
			<Footer />
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import HeroSection from '@/components/HeroSection'
import Slider2 from '@/components/Slider2.vue'
import Footer from '@/components/Footer.vue'
import Reviews from '@/components/Reviews'
import { axios } from '../api/index'
import 'vue-select/dist/vue-select.css'
import CitiesPicker from '@/components/CitiesPicker'
import ExploreSlider from '@/components/ExploreSlider'
import CitiesPickerMobile from '@/components/CitiesPickerMobile'

export default {
	name: 'Home',
	components: {
		Slider2,
		Footer,
		HeroSection,
		Reviews,
		CitiesPicker,
		ExploreSlider,
		CitiesPickerMobile,
	},
	data() {
		return {
			newsLogos: [{ src: 'img/Hesspress.png' }, { src: 'img/2M.png' }, { src: 'img/Tele Maroc.png' }, { src: 'img/SNRT HOCIEMA.png' }, { src: 'img/Cap Radio.png' }, { src: 'img/Press Tetouan.png' }, { src: 'img/Chamal Post.png' }, { src: 'img/Ana Lkhabar.png' }, { src: 'img/Akhbarona.png' }, { src: 'img/Majala 24.png' }, { src: 'img/Radio Tetouan.png' }, { src: 'img/mdiqtv.png' }],
			//
			cities: [],

			value: null,
			selectedDepart: null,
			selectedDest: null,
		}
	},

	created() {
		this.fetchCities()
	},
	methods: {
		fetchCities() {
			axios
				.get('/cities')
				.then(({ data }) => {
					this.cities = data
				})
				.catch((err) => {})
		},

		submit() {
			this.$router.push({ path: 'trip', query: { dep: this.selectedDepart.id, dest: this.selectedDest.id } })
		},
	},
}
</script>
