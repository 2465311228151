<template lang="">
	<div style="background-image: url('img/Bus.png'); background-size: cover; height: 700px">
		<nav class="container px-6 md:px-0 py-8 mx-auto md:flex md:justify-between md:items-center">
			<div class="flex items-center flex-col md:flex-row justify-start">
				<div class="flex items-center justify-between w-full">
					<router-link to="/" class="w-32">
						<img src="img/logo.png" alt="" class="" />
					</router-link>
					<div @click="showMenu = !showMenu" class="flex md:hidden">
						<button type="button" class="text-white focus:outline-none h-8">
							<svg v-if="!showMenu" viewBox="0 0 24 24" class="w-8 h-8 fill-current">
								<path fill-rule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"></path>
							</svg>
							<svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
								<line x1="18" y1="6" x2="6" y2="18"></line>
								<line x1="6" y1="6" x2="18" y2="18"></line>
							</svg>
						</button>
					</div>
				</div>
			</div>
			<div class="flex items-center">
				<ul :class="showMenu ? 'flex absolute left-0 right-0 top-16 shadow-lg' : 'hidden'" :style="showMenu ? 'background: linear-gradient(0deg, rgb(44 54 142) 109%, rgba(104,113,191,1) 109%)' : '' " class="  p-6 w-full md:ml-28 flex-col mt-8 space-y-4 md:flex md:space-y-0 md:flex-row md:items-center md:space-x-10 md:mt-0">
					<li v-for="item in menu" :key="item.id" clas="text-white opacity-75 hover:opacity-100">
						<router-link :to="item.path" class="w-32">
							<a href="#" class="text-white">{{ item.name }}</a>
						</router-link>
					</li>
				</ul>
				<a href="https://play.google.com/store/apps/details?id=com.appmosafir" class="hidden md:block w-48">
					<div>
						<img src="/img/GoogelPlay.png" />
					</div>
				</a>
			</div>
		</nav>
		<div class="container mx-auto flex mt-8 px-6 md:px-0">
			<div class="w-full md:w-3/4">
				<div class="text-white text-6xl md:text-7xl font-thin mb-4 md:mb-16">
					Your Travel Start <br class="hidden md:flex"/>
					from here<span class="text-Green">.</span>
				</div>
				<div class="text-white opacity-75 mb-8 md:mb-20 text-xl md:text-2xl">
					Find your Destination over <span class="text-Green">16000+</span> <br />
					Trips & Explore amazing Cities for a perfect day
				</div>
				<div class="md:hidden w-full md:w-100">
					<CitiesPickerMobile :selectedDepart="selectedDepart" :selectedDest="selectedDest" :cities="cities" />
				</div>
				<div class="hidden md:flex w-full md:w-100">
					<CitiesPicker :selectedDepart="selectedDepart" :selectedDest="selectedDest" :cities="cities" />
				</div>
			</div>
			<div class="flex flex-1"></div>
		</div>
	</div>
</template>
<script>
import Myicons from '@/components/Myicons'
import CitiesPicker from '@/components/CitiesPicker'
import CitiesPickerMobile from '@/components/CitiesPickerMobile'

export default {
	components: {
		Myicons,
		CitiesPicker,
		CitiesPickerMobile,
	},
	props: {
		cities: {
			type: Array,
		},
	},
	data() {
		return {
			showMenu: false,
			// selectedDepart: { name: '' },
			selectedDepart: false,
			// selectedDest: { name: '' },
			selectedDest: false,
			menu: [
				{ id: 1, name: 'Home', path: '/' },
				{ id: 1, name: 'Destinations', path: 'destinations' },
				// { id: 1, name: 'About', path: 'about' },
				{ id: 1, name: 'Contact', path: 'contact' },
			],
		}
	},
	created() {},
}
</script>
