<template>
	<div class="imgContainer relative overflow-hidden bg-white" :class="[mode, ratio === 'auto' ? 'auto' : 'ratio']" :style="{ paddingBottom }">
		<slot />
		<div :class="showInnerBorder ? 'innerBorder' : ''"></div>
	</div>
</template>

<script>
export default {
	props: {
		mode: { type: String, default: 'cover' },
		showInnerBorder: { type: Boolean, default: false },
		ratio: { type: String, default: 'auto' }
	},
	computed: {
		paddingBottom() {
			if (this.ratio === 'auto') return 'initial'
			let [a, b] = this.ratio.split(':')
			a = parseInt(a)
			b = parseInt(b)
			return (b / a) * 100 + '%'
		}
	}
}
</script>
<style>
.imgContainer {
	text-align: initial;
}
.imgContainer.ratio img {
	position: absolute;
	height: 100%;
	width: 100%;
}
.imgContainer.auto img {
	position: absolute;
	width: 100%;
	height: 100%;
}
.imgContainer.cover img {
	object-fit: cover;
}
.imgContainer.contain img {
	object-fit: contain;
}
.imgContainer .innerBorder {
	border: 1px solid #00000011;
	border-radius: inherit;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	pointer-events: none;
}
</style>
