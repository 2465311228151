import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import 'vue3-carousel/dist/carousel.css'
import VueGtag from 'vue-gtag'

// import vSelect from 'vue-select';

const CreatedApp = createApp(App)

CreatedApp.use(
	VueGtag,
	{
		config: {
			id: 'GTM-TJ6CXDG',
		},
	},
	router
)

CreatedApp.use(store).use(router).mount('#app')
