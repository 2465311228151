<template>
	<div class="flex flex-wrap -m-2">
		<template v-for="(item, index) in cities" :key="index">
			<div class="w-1/2 md:w-1/4 p-2">
				<div class="relative group flex justify-center items-center h-52 w-full rounded-lg skeleton"></div>
			</div>
		</template>
	</div>
</template>

<script>
export default {
	data() {
		return {
			cities: [1, 2, 3, 4, 5, 6, 7, 8],
		}
	},
}
</script>
