<template>
 <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div>  -->
  <router-view/>
</template>
  <script>
  </script>
<style>

*{
  font-family: 'Poppins';
  font-weight: 300;
}

</style>
