<template lang="">
	<div class="bg-blue-lightest">
		<nav class="container px-6 py-8 mx-auto md:flex md:justify-between md:items-center">
			<div class="flex items-center flex-col md:flex-row justify-start">
				<div class="flex items-center justify-between w-full">
					<router-link to="/" class="w-32">
						<img src="/img/logo_dark.png" alt="" class="" />
					</router-link>

					<div @click="showMenu = !showMenu" class="flex md:hidden">
						<button type="button" class="text-Blue focus:outline-none">
							<svg viewBox="0 0 24 24" class="w-8 h-8 fill-current">
								<path fill-rule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"></path>
							</svg>
						</button>
					</div>
				</div>
			</div>
			<div class="flex items-center">
				<ul :class="showMenu ? 'flex absolute bg-blue-lightest p-6 top-16 left-0 right-0 z-50 shadow' : 'hidden'" class="  w-full md:ml-28 flex-col mt-8 space-y-4 md:flex md:space-y-0 md:flex-row md:items-center md:space-x-10 md:mt-0">
					<li v-for="item in menu" :key="item.id" clas=" opacity-75 hover:opacity-100">
						<router-link :to="item.path" class="w-32">
							<a href="#" class="text-gray-600">{{ item.name }}</a>
						</router-link>
					</li>
				</ul>
				<div class="hidden md:block w-48">
					<a href="https://play.google.com/store/apps/details?id=com.appmosafir">
						<img src="/img/GoogelPlay.png" />
					</a>
				</div>
			</div>
		</nav>
	</div>
</template>
<script>
import Myicons from '@/components/Myicons'
export default {
	components: {
		Myicons,
	},
	data() {
		return {
			showMenu: false,
			menu: [
				{ id: 1, name: 'Home', path: '/' },
				{ id: 1, name: 'Destinations', path: '/destinations' },
				// { id: 1, name: 'About', path: '/about' },
				{ id: 1, name: 'Contact', path: '/contact' },
			],
		}
	},
	created() {},
}
</script>
