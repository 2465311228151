<template>
	<div>
		<div class="md:hidden flex flex-col">
			<div class="flex flex-col items-start px-8 md:px-28 py-8 md:py-16 container mx-auto">
				<div class="flex flex-row w-full mb-2">
					<div class="w-1/2 flex flex-col">
						<p class="mb-4 font-semibold text-Blue">Information</p>
						<a class="text-gray-400 mb-2 md:text-gray-300 hover:text-white text-base transition duration-300 ease-in-ou" href="#"> Home </a>
						<a class="text-gray-400 md:text-gray-300 hover:text-white text-base transition duration-300 ease-in-ou" href="/trips"> Trips </a>
						<a class="text-gray-400 md:text-gray-300 hover:text-white text-base transition duration-300 ease-in-ou" href="#"> Features </a>
					</div>

					<div class="w-1/2 flex flex-col">
						<p class="mb-4 font-semibold text-Blue">Special Links</p>
						<a class="text-gray-400 mb-2 md:text-gray-300 hover:text-white text-base transition duration-300 ease-in-ou" href="/contact"> Contact </a>
						<a class="text-gray-400 mb-2 md:text-gray-300 hover:text-white text-base transition duration-300 ease-in-ou" href="/terms-of-use"> Terms of use </a>
						<a class="text-gray-400 md:text-gray-300 hover:text-white text-base transition duration-300 ease-in-ou" href="/Privacy-policy"> Privacy Policy </a>
					</div>
				</div>

				<!-- <div class="mb-2">
					<p class="text-gray-400">+212 6 71 89 20 03</p>
				</div> -->

				<div class="flex items-center justify-between w-full">
					<div class="w-1/2">
						<p class="text-gray-400">contact@mosafir.ma</p>
					</div>
					<div class="w-1/2 flex flex-row justify-end">
						<div v-for="(icon, index) in icons" :key="index" class="my-1">
							<a :href="icon.url">
								<myicons :name="icon.name" class="ml-2 text-white flex items-center justify-center bg-Green w-8 h-8 p-3 rounded-full" />
							</a>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div class="flex items-center justify-center text-Blue md:text-white py-4 text-sm">
					<myicons name="copyright" class="w-3 h-3 mr-2" />
					<p>2023 <a href="#"> Mosafir.ma.</a> All rights reserved</p>
				</div>
			</div>
		</div>
		<div class="hidden md:flex flex-col bg-white md:bg-Blue">
			<div class="flex flex-col md:flex-row items-start px-8 md:px-28 py-8 md:py-16 container mx-auto">
				<div class="w-full md:w-1/4 flex flex-col">
					<a href="#"><img src="img/logo.png" alt="" class="hidden md:flex w-32 cursor-pointer" /></a>
					<div class="flex items-center my-4">
						<div v-for="(icon, index) in icons" :key="index" class="my-1 mr-4 cursor-pointer">
							<a :href="icon.url">
								<myicons :name="icon.name" class="text-white flex items-center justify-center bg-Green w-9 h-9 p-3 rounded-full" />
							</a>
						</div>
					</div>
				</div>
				<div class="w-full md:w-1/4">
					<div v-for="(item, index) in homeItems" :key="index" class="">
						<a href="#">
							<p class="text-gray-500 md:text-gray-300 hover:text-white text-base p-2 transition duration-300 ease-in-ou">{{ item.name }}</p>
						</a>
					</div>
				</div>
				<div class="w-full md:w-1/4">
					<div v-for="(item, index) in contactItems" :key="index" class="">
						<a href="#">
							<p class="md:text-gray-300 text-white p-2 hover:text-white text-base transition duration-300 ease-in-ou">{{ item.name }}</p>
						</a>
					</div>
				</div>
				<div class="w-full md:w-1/4"></div>
			</div>
			<div class="border-t opacity-20 border-white mx-28"></div>
			<div class="flex items-center justify-center text-Blue md:text-white py-4 text-sm">
				<myicons name="copyright" class="w-3 h-3 mr-2" />
				<p>2023 <a href="#"> Mosafir.ma.</a> All rights reserved</p>
			</div>
		</div>
	</div>
</template>

<script>
import Myicons from '@/components/Myicons.vue'
export default {
	components: {
		Myicons,
	},
	data() {
		return {
			icons: [
				{ name: 'facebook', url: 'https://www.facebook.com/mosafir.ma' },
				{ name: 'twitter', url: 'https://www.twitter.com/mosafirma' },
				{ name: 'instagram', url: 'https://www.instagram.com/mosafir.ma' },
			],
			homeItems: [{ name: 'Home' }, { name: 'Trips', link: '/trips'}, { name: 'Features' }],
			tripsItems: [{ name: 'Trips', link: '/trips' }, { name: 'Cities guide' }, { name: 'Book trips' }, { name: 'FAQs' }],
			contactItems: [{ name: 'Contact' }, { name: 'Terms of use' }, { name: 'Privacy Policy' }],
		}
	},
}
</script>
