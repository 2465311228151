<template>
	<div class="container mx-auto"  >
		<h1 class="text-Blue text-center md:text-left text-3xl md:text-5xl mt-12 md:mt-28 mb-4 md:mb-14">Explore Morocco<span class="text-Green">.</span></h1>
		<div class="flex items-center justify-between mb-4 md:mb-14">
			<div class="text-center md:text-left  text-gray-500  text-lg w-full md:w-2/3">Morocco's amazing cities offer a variety of experiences. Marrakech captivates with its vibrant markets and palaces. Fes boasts a historic medina and ancient university. Chefchaouen enchants with its blue-painted streets. Casablanca showcases a modern metropolis, and Essaouira delights with its coastal charm.</div>
		</div>
		<template v-if="!!destinations.length">
			<Carousel :items-to-show="2" :breakpoints="breakpoints" :autoplay="3000">
				<Slide v-for="(item, index) in destinations" :key="index">
					<div class="w-64 h-64 m-2 rounded-lg">
						<router-link :to="generateURL(item)">
							<div @click="goToCity(item)" class="relative group flex justify-center items-center h-full w-full rounded-lg overflow-hidden shadow cursor-pointer">
								<img class="object-center object-cover h-full w-full" :src="item.image" alt="city-image" />
								<span style="background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.6));" class="bottom-0 left-0 right-0 z-10 absolute text-base font-medium text-center text-white py-3"> {{ item.name }} </span>
							</div>
						</router-link>
					</div>
				</Slide>

				<template #addons>
					<!-- <Navigation /> -->
					<Pagination />
				</template>
			</Carousel>
		</template>
	</div>
</template>

<script>
import Myicons from '@/components/Myicons.vue'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import { axios } from '../api/index'

export default {
	components: {
		Myicons,
		Carousel,
		Slide,
		Pagination,
		Navigation,
	},
	data() {
		return {
			destinations: [],
			breakpoints: {
				// 700px and up
				700: {
					itemsToShow: 4,
					snapAlign: 'center',
				},
				// 1024 and up
				1024: {
					itemsToShow: 5,
					snapAlign: 'start',
				},
			},
			cities: [],
		}
	},
	created() {
		axios
			.get('/destinations?limit=8')
			.then(({ data }) => {
				this.destinations = data
			})
			.catch((err) => {})
	},
	methods:{
		generateURL(item){
			return `/destinations/${item.id}-${item.name.toLowerCase()}`
		}
	}
}
</script>

<style>
.carousel__prev,
.carousel__next,
.carousel__pagination-button {
	background-color: #88c440;
}

.carousel__pagination-button{
	width:10px !important;
	height:10px !important;
}
</style>
