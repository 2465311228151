<template lang="">
	<div>
		<div class="text-left md:text-center mb-8">
			<h1 class="text-Blue text-4xl font-thin my-8">What Our Customers Say <span class="text-Green">.</span></h1>
			<p class="text-gray-400 text-lg text-center mx-auto">We are delighted to share some testimonials from our esteemed customers <br class="hidden md:block" />
				who have expressed their utmost satisfaction and positive experiences </p>
		</div>
		<div class="container mx-auto">
			<Carousel :items-to-show="1" :breakpoints="breakpoints" :autoplay="3000">
				<Slide v-for="(slide, index) in reviews" :key="index">
					<div class="bg-white shadow-lg m-4 rounded-xl py-4 px-6 w-full">
						<div class="text-lg text-Blue font-semibold mb-2">{{ slide.name }}</div>
						<div class="text-xs text-gray-500 mb-2 italic">(play store)</div>
						<div class="flex items-center justify-center mb-6">
							<div v-for="star in 5" :key="star" class="fill-current text-yellow-400 w-4">
								<svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
									<title>Star</title>
									<path d="M394 480a16 16 0 01-9.39-3L256 383.76 127.39 477a16 16 0 01-24.55-18.08L153 310.35 23 221.2a16 16 0 019-29.2h160.38l48.4-148.95a16 16 0 0130.44 0l48.4 149H480a16 16 0 019.05 29.2L359 310.35l50.13 148.53A16 16 0 01394 480z" />
								</svg>
							</div>
						</div>
						<p class="text-sm text-gray-500">
							{{ slide.content }}
						</p>
					</div>
				</Slide>
			</Carousel>
		</div>
	</div>
</template>
<script>
// import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

export default {
	components: {
		Carousel,
		Slide,
		Pagination,
		Navigation,
	},
	data() {
		return {
			breakpoints: {
				// 700px and up
				700: {
					itemsToShow: 3,
					snapAlign: 'center',
				},
			},
			reviews: [
				{
					name: 'Outmane EL OUKILI',
					content: 'A great application that makes it easier for travelers to know bus times without the trouble of heading to the station and waiting for a long time',
				},
				{
					name: 'Mohamed El-Merabet ',
					content: "We've always been looking for travel information and we don't find. A great idea offered by this app thank you so much !",
				},
				{
					name: 'Rima Mrt',
					content: 'Easy and practical application, thank you for your services',
				},
				{
					name: 'Mohamed Murad',
					content: 'Thank you for your great efforts to provide this useful service',
				},
				{
					name: 'Yassine Bousbaa',
					content: 'A Really helpfull app and easy to use.',
				},
			],
		}
	},
}
</script>
