<template>
	<div class="w-full bg-white rounded-xl border border-gray-200 pb-2 md:p-6 relative">
		<div class="flex items-center justify-center">
			<div class="w-1/3">
				<div class="p-0 md:p-2 md:p-3 flex items-center justify-center">
					<div class="hidden md:flex w-8">
						<img src="img/bus2.png" />
					</div>
					<div class="ml-3 text-left ml-6 text-sm font-medium text-Blue flex-1">{{ trip.bus }}</div>
				</div>
			</div>
			<div class="w-2/3 flex items-center justify-center">
				<div class="w-1/3">
					<div class="p-3 text-center">
						<div class="text-left text-Blue text-xs md:text-sm">Departure</div>
						<div class="text-left text-xl md:text-2xl text-Green">{{ trip.d_time }}</div>
						<div class="text-left text-Blue text-xs md:text-sm">{{ trip.d_city }}</div>
					</div>
				</div>
				<div class="w-1/3 text-center">
					<div class="text-Blue text-xs md:text-sm">{{ trip.duration }}</div>
					<div class="border-0 border-dotted border-t-4 my-3 relative mr-2"></div>
					<div class="text-Blue text-xs md:text-sm">{{ trip.stations.length }} stops</div>
				</div>
				<div class="w-1/3">
					<div class="p-3 text-center">
						<div class="text-left text-Blue text-xs md:text-sm">Destination</div>
						<div class="text-left text-xl md:text-2xl text-Green">{{ trip.a_time }}</div>
						<div class="text-left text-Blue text-xs md:text-sm">{{ trip.a_city }}</div>
					</div>
				</div>
			</div>

			<!-- <div class="w-1/4 flex flex-1">
				<div class="p-0 md:p-2 md:p-3 flex items-center justify-center">
					<div class="hidden md:flex w-8">
						<img src="img/bus2.png" />
					</div>
					<div class="ml-3 text-left ml-6 text-sm font-medium text-Blue  flex-1">{{ trip.bus }}</div>
				</div>
			</div>
			<div class="w-1/4 ">
				<div class="p-3 text-center">
					<div class="text-left text-Blue text-xs md:text-sm">Departure</div>
					<div class="text-left text-xl md:text-2xl text-Green">{{ trip.d_time }}</div>
					<div class="text-left text-Blue text-xs md:text-sm">{{ trip.d_city }}</div>
				</div>
			</div>
			<div class="w-1/4 text-left">
				<div class="text-Blue text-xs md:text-sm">{{ trip.duration }}</div>
				<div class="border-0 border-dotted border-t-4 my-3 relative mr-16">
				</div>
				<div class="text-Blue text-xs md:text-sm">{{ trip.stations.length }} stops</div>
			</div>
			<div class="w-1/4 ">
				<div class="p-3 text-center">
					<div class="text-left text-Blue text-xs md:text-sm">Destination</div>
					<div class="text-left text-xl md:text-2xl text-Green">{{ trip.a_time }}</div>
					<div class="text-left text-Blue text-xs md:text-sm">{{ trip.a_city }}</div>
				</div>
			</div> -->
		</div>
		<div v-if="showDetails" class="px-12 mt-8">
			<div class="flex">
				<div class="w-3/4">
					<template v-for="(stop, index) in stops" :key="index">
						<div class="flex items-center h-12  relative">
							<div style="left: -3px" class="absolute h-12 rounded rounded-3xl border-dotted border-gray-200 border-0 border-l-2"></div>
							<div style="left: -8px" class="absolute bg-Green h-3 rounded rounded-3xl w-3"></div>

							<div class="text-Green mx-8 w-14 text-xl md:text-2xl">{{ stop.time }}</div>
							<div class="text-gray-600 text-sm">{{ stop.city }}</div>
						</div>
					</template>
				</div>
				<div class="w-1/4">
					<div v-if="trip.services.wifi == 1" class="flex items-center mb-4">
						<div class="mr-2 w-6 text-Green">
							<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="wifi" class="svg-inline--fa fa-wifi" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M319.1 351.1c-35.35 0-64 28.66-64 64.01s28.66 64.01 64 64.01c35.34 0 64-28.66 64-64.01S355.3 351.1 319.1 351.1zM320 191.1c-70.25 0-137.9 25.6-190.5 72.03C116.3 275.7 115 295.9 126.7 309.2C138.5 322.4 158.7 323.7 171.9 312C212.8 275.9 265.4 256 320 256s107.3 19.88 148.1 56C474.2 317.4 481.8 320 489.3 320c8.844 0 17.66-3.656 24-10.81C525 295.9 523.8 275.7 510.5 264C457.9 217.6 390.3 191.1 320 191.1zM630.2 156.7C546.3 76.28 436.2 32 320 32S93.69 76.28 9.844 156.7c-12.75 12.25-13.16 32.5-.9375 45.25c12.22 12.78 32.47 13.12 45.25 .9375C125.1 133.1 220.4 96 320 96s193.1 37.97 265.8 106.9C592.1 208.8 600 211.8 608 211.8c8.406 0 16.81-3.281 23.09-9.844C643.3 189.2 642.9 168.1 630.2 156.7z"></path></svg>
						</div>
						<span class="text-sm md:text-base text-gray-600 truncate">WiFi</span>
					</div>
					<div v-if="trip.services.highway == 1" class="flex items-center mb-4">
						<div class="mr-2 w-6 text-Green">
							<svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
								<path fill="currentColor" d="M10,2L8,8H11V2H10M13,2V8H16L14,2H13M2,9V10H4V11H6V10H18L18.06,11H20V10H22V9H2M7,11L3.34,22H11V11H7M13,11V22H20.66L17,11H13Z" />
							</svg>
						</div>
						<span class="text-sm md:text-base text-gray-600 truncate">Highway</span>
					</div>
					<div v-if="trip.services.climate == 1" class="flex items-center mb-4">
						<div class="mr-2 w-6 text-Green">
							<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="snowflake" class="svg-inline--fa fa-snowflake" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
								<path
									fill="currentColor"
									d="M484.4 294.4c1.715 6.402 .6758 12.89-2.395 18.21s-8.172 9.463-14.57 11.18l-31.46 8.43l32.96 19.03C480.4 357.8 484.4 372.5 477.8 384s-21.38 15.41-32.86 8.783l-32.96-19.03l8.43 31.46c3.432 12.81-4.162 25.96-16.97 29.39s-25.96-4.162-29.39-16.97l-20.85-77.82L280 297.6v84.49l56.97 56.97c9.375 9.375 9.375 24.56 0 33.94C332.3 477.7 326.1 480 320 480s-12.28-2.344-16.97-7.031L280 449.9V488c0 13.25-10.75 24-24 24s-24-10.75-24-24v-38.06l-23.03 23.03c-9.375 9.375-24.56 9.375-33.94 0s-9.375-24.56 0-33.94L232 382.1V297.6l-73.17 42.25l-20.85 77.82c-3.432 12.81-16.58 20.4-29.39 16.97s-20.4-16.58-16.97-29.39l8.43-31.46l-32.96 19.03C55.61 399.4 40.85 395.5 34.22 384s-2.615-26.16 8.859-32.79l32.96-19.03l-31.46-8.43c-12.81-3.432-20.4-16.58-16.97-29.39s16.58-20.4 29.39-16.97l77.82 20.85L208 255.1L134.8 213.8L57.01 234.6C44.2 238 31.05 230.4 27.62 217.6s4.162-25.96 16.97-29.39l31.46-8.432L43.08 160.8C31.61 154.2 27.6 139.5 34.22 128s21.38-15.41 32.86-8.785l32.96 19.03L91.62 106.8C88.18 93.98 95.78 80.83 108.6 77.39s25.96 4.162 29.39 16.97l20.85 77.82L232 214.4V129.9L175 72.97c-9.375-9.375-9.375-24.56 0-33.94s24.56-9.375 33.94 0L232 62.06V24C232 10.75 242.8 0 256 0s24 10.75 24 24v38.06l23.03-23.03c9.375-9.375 24.56-9.375 33.94 0s9.375 24.56 0 33.94L280 129.9v84.49l73.17-42.25l20.85-77.82c3.432-12.81 16.58-20.4 29.39-16.97c6.402 1.715 11.5 5.861 14.57 11.18s4.109 11.81 2.395 18.21l-8.43 31.46l32.96-19.03C456.4 112.6 471.2 116.5 477.8 128s2.615 26.16-8.859 32.78l-32.96 19.03l31.46 8.432c12.81 3.432 20.4 16.58 16.97 29.39s-16.58 20.4-29.39 16.97l-77.82-20.85L304 255.1l73.17 42.25l77.82-20.85C467.8 273.1 480.1 281.6 484.4 294.4z"
								></path>
							</svg>
						</div>
						<span class="text-sm md:text-base text-gray-600 flex-1 truncate">Air Conditioned</span>
					</div>
				</div>
			</div>
		</div>
		<button @click="showDetails = !showDetails" class="absolute bottom-0 right-10 w-4 h-4 md:w-6 md:h-6 text-white bg-gray-300 rounded-tr-lg rounded-tl-lg flex items-center justify-center">
			<div class="w-4 h-4">
				<svg v-if="showDetails" xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
					<title>Chevron Up</title>
					<path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M112 328l144-144 144 144" />
				</svg>
				<svg v-else xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
					<title>Chevron Down</title>
					<path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M112 184l144 144 144-144" />
				</svg>
			</div>
		</button>
	</div>
</template>
<script>
export default {
	props: ['trip'],
	data() {
		return {
			showDetails: false,
		}
	},
	mounted() {},
	computed: {
		stops() {
			return this.trip?.stations || []
		},
	},
}
</script>

<style></style>
