<template>
	<div class="flex flex-wrap -m-1">
		<template v-if="!!images.length">
			<template v-for="(image, i) in localImages"  :key="i">
				<div @click="index = i" class="cursor-pointer p-1" :class="computedClass(i)">
					<CenterImg :ratio="computedRatio(i)" class="rounded-lg" showInnerBorder>
						<img :src="image" alt="gallery" />
						<template v-if="restOfCount > 0 && i + 1 === maxToDisplay">
							<div class="absolute inset-0 flex justify-center items-center text-white text-3xl pointer-events-none" style="background: #00000055">+{{ restOfCount }}</div>
						</template>
					</CenterImg>
				</div>
			</template>
			<vue-gallery-slideshow :images="images" :index="index" @close="index = null" />
		</template>
		<div class="w-full" v-else>
			<!-- <UiCenterImg ratio="5:2" class="rounded-lg" showInnerBorder>
				<img src="img/sss" alt="gallery" />
			</UiCenterImg> -->
		</div>
	</div>
</template>

<script>
import _ from 'lodash'
import VueGallerySlideshow from 'vue-gallery-slideshow'

import CenterImg from './CenterImage'

export default {
	components: { VueGallerySlideshow, CenterImg },
	props: {
		images: { type: Array, required: true },
		maxToDisplay: { type: Number, default: 5 },
	},
	data() {
		return {
			index: null,
		}
	},
	computed: {
		localImages() {
			return _.take(this.images, this.maxToDisplay)
		},
		restOfCount() {
			return this.images.length - this.localImages.length
		},
	},
	methods: {
		computedClass(index) {
			let length = this.localImages.length
			if (length === 1) return 'w-full'
			else if (length === 2) return 'w-1/2'
			else if (length === 4) return 'w-1/2'
			else {
				if (index === 0) return 'w-full'
				else return `w-1/${length - 1}`
			}
		},
		computedRatio(index) {
			let length = this.localImages.length
			if (length === 1) return '2:1'
			else if (length === 2) return '1:1'
			else if (length === 4) return '1:1'
			else {
				if (index === 0) return '3:1'
				else return '1:1'
			}
		},
	},
}
</script>

<style></style>
