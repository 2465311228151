<template>
	<div class="container px-6 py-8 md:py-20 mx-auto">
		<div class="flex md:flex-row flex-col-reverse">
			<div class="w-full md:w-1/2 pr-o md:pr-12">
				<div class="text-4xl text-Blue mb-4 h-2 skeleton mb-3"></div>
				<div class="">
					<div class="w-1/2 h-2 mb-2 skeleton"></div>
					<div class="w-1/5 h-2 mb-2 skeleton"></div>
					<div class="w-1/3 h-2 mb-2 skeleton"></div>
					<div class="w-1/3 h-2 mb-2 skeleton"></div>
					<div class="w-1/2 h-2 mb-2 skeleton"></div>
					<div class="w-1/5 h-2 mb-2 skeleton"></div>
					<div class="w-1/3 h-2 mb-2 skeleton"></div>
				</div>
				<div class="w-24 h-8 mt-6 rounded skeleton"></div>
			</div>
			<div class="w-full md:w-1/2 skeleton rounded-lg h-72"></div>
		</div>
	</div>
</template>
