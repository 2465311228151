<template>
	<div class=" ">
		<!-- Header -->
		<Header />
		<!-- Body -->
		<div v-if="loading" >
			<CityPagePlaceHolder />
		</div>
		<div v-else class="container px-6 py-8 md:py-20 mx-auto">
			<div class="flex md:flex-row flex-col-reverse">
				<div class="w-full md:w-1/2 pr-o md:pr-12">
					<h2 class="text-4xl text-Blue mb-4">{{ destanation.title }}</h2>
					<p class="text-gray-500 mb-4" v-html="destanation.content"></p>
					<button class="flex items-center bg-Green text-white p-2 rounded-lg">
						<div class="fill-current text-white w-4 mr-2">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
								<path d="M408 120C408 174.6 334.9 271.9 302.8 311.1C295.1 321.6 280.9 321.6 273.2 311.1C241.1 271.9 168 174.6 168 120C168 53.73 221.7 0 288 0C354.3 0 408 53.73 408 120zM288 152C310.1 152 328 134.1 328 112C328 89.91 310.1 72 288 72C265.9 72 248 89.91 248 112C248 134.1 265.9 152 288 152zM425.6 179.8C426.1 178.6 426.6 177.4 427.1 176.1L543.1 129.7C558.9 123.4 576 135 576 152V422.8C576 432.6 570 441.4 560.9 445.1L416 503V200.4C419.5 193.5 422.7 186.7 425.6 179.8zM150.4 179.8C153.3 186.7 156.5 193.5 160 200.4V451.8L32.91 502.7C17.15 508.1 0 497.4 0 480.4V209.6C0 199.8 5.975 190.1 15.09 187.3L137.6 138.3C140 152.5 144.9 166.6 150.4 179.8H150.4zM327.8 331.1C341.7 314.6 363.5 286.3 384 255V504.3L192 449.4V255C212.5 286.3 234.3 314.6 248.2 331.1C268.7 357.6 307.3 357.6 327.8 331.1L327.8 331.1z" />
							</svg>
						</div>
						Map View
					</button>
				</div>
				<div class="w-full md:w-1/2">
					<Carousel v-if="images.length !== 0" :items-to-show="1">
						<Slide v-for="(img, index) in images" :key="index">
							<div class="w-full p-2">
								<div class="relative group flex justify-center items-center h-100 w-full rounded-lg overflow-hidden shadow cursor-pointer">
									<img class="object-center object-cover h-full w-full" :src="img.image" alt="city-image" />
								</div>
							</div>
						</Slide>

						<template #addons>
							<Navigation />
							<Pagination />
						</template>
					</Carousel>
				</div>
			</div>
			<!-- Show listing 
			<div class="flex justify-center items-center mt-6">
				<div class="container flex flex-col">
					<h2 class="font-semibold text-xl text-Blue my-6">Eat & Drink <span class="text-sm text-gray-600">View all({{ eatPlaces.length }})</span></h2>
					<div>
						<div class="flex flex-wrap -m-2">
							<template v-for="(item, index) in eatPlaces" :key="index">
								<div class="w-1/4 p-2">
									<div class="card relative group flex justify-center items-center h-56 w-full rounded-lg overflow-hidden shadow cursor-pointer">
										<img class="object-center object-cover h-full w-full" :src="`https://mosafir.ma/uploads/images/${item.image}`" alt="city-image" />

										<div class="left-2 bottom-2 z-10 absolute flex flex-col">
											<span class="text-lg  text-white">{{ item.name }}</span>
											<div class="text-sm text-white flex items-center">
												<Icon name="star" class="fill-current text-yellow-400 w-4" />
												<span class="mx-2" >(5 Reviews)</span> <span class="text-yellow-400" >{{ item.rating }}</span>
											</div>
										</div>
									</div>
								</div>
							</template>
						</div>
					</div>
					<h2 class="font-semibold text-xl text-Blue my-6">See & Do <span class="text-sm text-gray-600">View all({{ seePlaces.length }})</span></h2>
					<div>
						<div class="flex flex-wrap -m-2">
							<template v-for="(item, index) in seePlaces" :key="index">
								<div class="w-1/4 p-2">
									<div class="card relative group flex justify-center items-center h-56 w-full rounded-lg overflow-hidden shadow cursor-pointer">
										<img class="object-center object-cover h-full w-full" :src="`https://mosafir.ma/uploads/images/${item.image}`" alt="city-image" />


										<div class="left-2 bottom-2 z-10 absolute flex flex-col">
											<span class="text-xl font-medium text-white">{{ item.name }}</span>
											<div class="text-xl font-medium text-white flex items-center">
												<Icon name="star" class="fill-current text-Green" />
												{{ item.rating }}
											</div>
										</div>
									</div>
								</div>
							</template>
						</div>
					</div>
					<h2 class="font-semibold text-xl text-Blue my-6">Stay <span class="text-sm text-gray-600">View all({{ stayPlaces.length }})</span></h2>
					<div>
						<div class="flex flex-wrap -m-2">
							<template v-for="(item, index) in stayPlaces" :key="index">
								<div class="w-1/4 p-2">
									<div class="card relative group flex justify-center items-center h-56 w-full rounded-lg overflow-hidden shadow cursor-pointer">
										<img class="object-center object-cover h-full w-full" :src="`https://mosafir.ma/uploads/images/${item.image}`" alt="city-image" />

										<div class="left-2 bottom-2 z-10 absolute flex flex-col">
											<span class="text-xl font-medium text-white">{{ item.name }}</span>
											<div class="text-xl font-medium text-white flex items-center">
												<Icon name="star" class="fill-current text-Green" />
												{{ item.rating }}
											</div>
										</div>
									</div>
								</div>
							</template>
						</div>
					</div>
				</div>
			</div>-->
		</div>
		<div class="mt-20">
			<Footer />
		</div>
	</div>
</template>
<script>
import MyHeader from '@/components/MyHeader'
import Myicons from '@/components/Myicons.vue'
import TripCard from '@/components/trip/TripCard.vue'
import Loader from '@/components/Loader.vue'
import Multiselect from 'vue-multiselect'
import { axios } from '../api/index'
import Footer from '@/components/Footer.vue'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import Gallery from '@/components/Gallery'
import CityPagePlaceHolder from '@/components/placeholders/CityPagePlaceHolder'

export default {
	components: {
		Header: MyHeader,
		Icon: Myicons,
		TripCard,
		Multiselect,
		Loader,
		Footer,
		Carousel,
		Slide,
		Pagination,
		Navigation,
		Gallery,
		CityPagePlaceHolder,
	},
	data() {
		return {
			loading: true,
			destanation: {
				title: '',
				content: '',
				image: '',
			},
		}
	},
	created() {
		axios
			.get(`/destinations?id=${this.$route?.params?.id}`)
			.then(({ data }) => {
				this.destanation = data
			})
			.catch((err) => {})
			.finally(() => {
				this.loading = false
			})
	},
	computed: {
		images() {
			return this.destanation.images || []
		},
		eatPlaces() {
			return this.destanation?.places?.eat || []
		},
		stayPlaces() {
			return this.destanation?.places?.stay || []
		},
		seePlaces() {
			return this.destanation?.places?.see || []
		},
	},
	methods: {},
}
</script>
